import React from "react";

const Error404 = ({ location }) => {
  return (
    <div className="flex items-center justify-center mt-8 px-4">
      <div className="w-full md:w-1/2 border border-gray-300 p-4 text-center rounded">
        <p className="text-gray-600">
          The page you are looking for does not exist.
        </p>
      </div>
    </div>
  );
};

export default Error404;
