import * as localForage from "localforage";

import React, { useEffect, useState } from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import AppContext from "components/context/app";
import { COUNTIES_QUERY } from "domain/counties/graphql/queries";
import Error404 from "views/other/Error404";
import apollo from "lib/apollo";
import ScrollToTop from "components/routes/ScrollToTop";
import GoogleAnalytics from "components/routes/GoogleAnalytics";

const Loading = () => {
  return <div></div>;
};

function App() {
  const [state, setState] = useState({
    loaded: false,
    counties: null,
    modals: {
      entitySearch: false,
      seasonUnitSearch: false,
    },
    position: null,
  });
  useEffect(() => {
    const getAppData = async () => {
      let counties;

      counties = await localForage.getItem(
        "pigskin_empires.counties.by_fips_code"
      );
      if (!counties) {
        const { data } = await apollo.query({
          query: COUNTIES_QUERY,
        });
        const formattedPayload = {};
        data.counties.forEach(
          (item) => (formattedPayload[item.fips_code] = item)
        );
        counties = formattedPayload;
        await localForage.setItem(
          "pigskin_empires.counties.by_fips_code",
          counties
        );
      }
      setState((prevState) => ({ ...prevState, counties: counties }));
      setState((prevState) => ({ ...prevState, loaded: true }));
    };

    getAppData();
  }, []);
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    document.body.appendChild(script);
  }, []);

  return (
    <ApolloProvider client={apollo}>
      <AppContext.Provider value={{ state, setState }}>
        <React.Suspense fallback={<Loading />}>
          <Router>
            <div>
              {state.loaded && (
                <>
                  <ScrollToTop />
                  <GoogleAnalytics />
                  <Switch>
                    <Route
                      path="/:entity(map|games|rankings|teams|counties)/:association(fbs|nfl|nba)"
                      component={React.lazy(() =>
                        import(
                          /* webpackChunkName: "association-entry-view", webpackPrefetch: true */ "./views/AssociationEntryView"
                        )
                      )}
                    />
                    <Route
                      path="/legal/terms"
                      component={React.lazy(() =>
                        import(
                          /* webpackChunkName: "legal-terms-of-use-view" */ "./domain/more/views/TermsOfUseView"
                        )
                      )}
                    />
                    <Route
                      path="/legal/privacy"
                      component={React.lazy(() =>
                        import(
                          /* webpackChunkName: "legal-privacy-policy-view" */ "./domain/more/views/PrivacyPolicyView"
                        )
                      )}
                    />
                    <Route
                      path="/more/contact-us"
                      component={React.lazy(() =>
                        import(
                          /* webpackChunkName: "more-contact-us-view" */ "./domain/more/views/ContactUsView"
                        )
                      )}
                    />
                    <Route
                      exact
                      path="/"
                      render={(props) => {
                        if (process.env.NODE_ENV !== "production") {
                          return <Redirect to={`/map/fbs`} />;
                        }
                        if (
                          document.location.hostname === "pigskinempires.com"
                        ) {
                          return <Redirect to={`/map/fbs`} />;
                        }
                        if (
                          document.location.hostname === "hardcourtempires.com"
                        ) {
                          return <Redirect to={`/map/nba`} />;
                        }
                      }}
                    />
                    <Route path="" component={Error404} />
                  </Switch>
                </>
              )}
            </div>
          </Router>
        </React.Suspense>
      </AppContext.Provider>
    </ApolloProvider>
  );
}

export default App;
