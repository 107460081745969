import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

let httpLink;

if (process.env.NODE_ENV === "production") {
  const url = document.location.hostname.includes("hardcourtempires.com")
    ? process.env.REACT_APP_HARDCOURT_EMPIRES_GRAPHQL_URL
    : process.env.REACT_APP_PIGSKIN_EMPIRES_GRAPHQL_URL;
  httpLink = createHttpLink({ uri: url });
} else {
  httpLink = createHttpLink({ uri: process.env.REACT_APP_GRAPHQL_URL });
}

const authLink = setContext(async (_, { headers, ...context }) => {
  const authHeader = `Bearer ${process.env.REACT_APP_PUBLIC_ACCESS_TOKEN}`;
  return {
    headers: {
      ...headers,
      authorization: authHeader,
    },
    ...context,
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  credentials: "include",
  cache: new InMemoryCache(),
});

export default client;
