import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga";

const gaCodes = {
  "pigskinempires.com": process.env.REACT_APP_PIGSKIN_EMPIRES_GA_CODE,
  "hardcourtempires.com": process.env.REACT_APP_HARDCOURT_EMPIRES_GA_CODE,
};

const constructPageviewUrl = (location) => {
  return `${location.pathname}${location.search}`;
};

ReactGA.initialize(gaCodes[document.location.hostname]);

function GoogleAnalytics({ history }) {
  useEffect(() => {
    let unlisten;
    if (process.env.NODE_ENV !== "production") {
      console.log(constructPageviewUrl(document.location));
      unlisten = history.listen((location) => {
        console.log(constructPageviewUrl(location));
      });
    } else {
      ReactGA.set({ page: constructPageviewUrl(document.location) }); // Update the user's current page
      ReactGA.pageview(constructPageviewUrl(document.location)); // Record a pageview for the given page
      unlisten = history.listen((location) => {
        ReactGA.set({ page: constructPageviewUrl(location) }); // Update the user's current page
        ReactGA.pageview(constructPageviewUrl(location)); // Record a pageview for the given page
      });
    }

    return () => {
      unlisten();
    };
  }, [history]);

  return null;
}

export default withRouter(GoogleAnalytics);
