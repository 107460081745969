import gql from "graphql-tag";

export const COUNTIES_QUERY = gql`
  query($where: counties_bool_exp, $limit: Int, $offset: Int) {
    counties(where: $where, limit: $limit, offset: $offset) {
      id
      name
      slug
      fips_code
      state {
        name
        abbrev
        fips_code
      }
      land_area
      population
    }
  }
`;

export const COUNTY_QUERY = gql`
  query($where: counties_bool_exp, $limit: Int, $offset: Int) {
    counties(where: $where, limit: $limit, offset: $offset) {
      id
      name
      slug
      fips_code
      state {
        name
        abbrev
        fips_code
      }
      land_area
      population
      ranking {
        population_rank
        land_area_rank
      }
    }
  }
`;

export const HISTORICAL_OWNERSHIP_QUERY = gql`
  query(
    $where: historical_county_ownership_bool_exp
    $order_by: [historical_county_ownership_order_by!]
    $limit: Int
    $offset: Int
  ) {
    historical_ownerships: historical_county_ownership(
      where: $where
      order_by: $order_by
      limit: $limit
      offset: $offset
    ) {
      count
      team {
        id
        name
        nickname
        slug
        colors
        color_index
        logo
      }
    }
  }
`;

export const COUNTY_OWNERSHIPS_QUERY = gql`
  query(
    $where: team_county_ownerships_bool_exp
    $order_by: [team_county_ownerships_order_by!]
    $limit: Int
    $offset: Int
  ) {
    ownerships: _team_county_ownerships(
      where: $where
      order_by: $order_by
      limit: $limit
      offset: $offset
    ) {
      id
      changed
      season_week {
        number
        season {
          year
        }
      }
      game {
        id
        date
      }
      team {
        id
        name
        nickname
        slug
        colors
        color_index
        logo
      }
    }
  }
`;
